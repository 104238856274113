import { RouterProvider } from "react-router-dom";
import { router } from "./routes/router";
import { App, Spin } from "antd";
import { DirtyProvider } from "./contexts/DirtyProvider";
import WebSocketComponent from "./components/websocket/WebSocketComponent";
import { Suspense, useEffect } from "react";

function DefaultApp() {

  useEffect(() => {
    window.document.title = `${process.env.REACT_APP_PROJECT_NAME}` || ``
  }, [])

  return (
    <App>
      <DirtyProvider>
        <WebSocketComponent>
          <Suspense
            fallback={
              <div className="loading-global">
                <Spin spinning></Spin>
              </div>
            }
          >
            <RouterProvider router={router} />
          </Suspense>
        </WebSocketComponent>
      </DirtyProvider>
    </App>
  );
}

export default DefaultApp;
