import { createBrowserRouter } from "react-router-dom";
import { routesConfig } from "../config/routes";
import { lazy } from "react";
import ScormVebView from "../pages/xapi-web-view";

// Lazy load các component
const AuthPage = lazy(() => import("../layout/auth-page"));
const DashboardRootPage = lazy(
  () => import("../layout/dashboard/DashboardRootPage")
);
const Page500 = lazy(() => import("../pages/500"));
const CourseDetail = lazy(() => import("../pages/course-detail"));
const CourseEnrollment = lazy(() => import("../pages/course-enrollment"));
const CourseEvaluation = lazy(() => import("../pages/course-evaluation"));
const CourseInformation = lazy(() => import("../pages/course-info"));
const SearchCourseInfo = lazy(() => import("../pages/course-info/search"));
const CourseTopics = lazy(() => import("../pages/course-info/search-by-topic"));
const ForgotPassword = lazy(() => import("../pages/forgot-password"));
const HomePage = lazy(() => import("../pages/home-page/HomePage"));
const IdentifyEkyc = lazy(() => import("../pages/identify-ekyc/IdentifyEkyc"));
const InfoUser = lazy(() => import("../pages/info-user/InfoUser"));
const LearningCourseStructure = lazy(
  () => import("../pages/learning-course-structure")
);
const Login = lazy(() => import("../pages/login-page/Login"));
const MyCourse = lazy(() => import("../pages/my-courses"));
const NotificationPage = lazy(() => import("../pages/notification"));
const PolicyInfo = lazy(() => import("../pages/policy-info/PolicyInfo"));
const Register = lazy(() => import("../pages/register/Register"));
const ResultDelete = lazy(() => import("../pages/result-delete/ResultDelete"));
const SavedCourses = lazy(() => import("../pages/saved-courses"));
const SearchSchools = lazy(() => import("../pages/school/SearchSchools"));
const SearchByTopic = lazy(() => import("../pages/search-by-topic"));
const SearchEducationalPrograms = lazy(
  () => import("../pages/search-educational-programs")
);
const StatisticsCourses = lazy(() => import("../pages/statistics/course"));
const StatisticsUniversities = lazy(
  () => import("../pages/statistics/university")
);
const StudySchedule = lazy(() => import("../pages/study-schedule"));
const SupportPage = lazy(() => import("../pages/support"));
const DetailSupport = lazy(() => import("../pages/support/detail"));
const SystemInfo = lazy(() => import("../pages/system-info/SystemInfo"));
const VerificationPage = lazy(
  () => import("../pages/verification-page/VerficationPage")
);
const ViewSystemSupportInformation = lazy(
  () => import("../pages/view system-support-information")
);
const Welcome = lazy(() => import("../pages/welcome/Welcome"));
const DeleteSuccess = lazy(
  () => import("../components/pages/delete-success/DeleteSuccess")
);
const BlogPage = lazy(() => import("../pages/blog"));
const CommentComponent = lazy(() => import("../pages/blog/components/comment"));
const TopicComponent = lazy(() => import("../pages/blog/components/topic"));
const GeneralCourseDetail = lazy(
  () => import("../pages/course-detail/general-course-detail")
);
const CourseProgramDetailTemplate = lazy(
  () => import("../pages/course-program-detail/course-program-detail-template")
);
const AccountDeletionPolicy = lazy(
  () =>
    import(
      "../pages/info-user/components/account-deletion-policy/AccountDeletionPolicy"
    )
);
const JoinPlatform = lazy(() => import("../pages/join-platform/JoinPlatform"));
const MySurvey = lazy(() => import("../pages/my-surveys"));
const SurveyRespond = lazy(
  () => import("../pages/my-surveys/components/survey-respond/SurveyRespond")
);
const AuthenticateRegister = lazy(
  () =>
    import(
      "../pages/register/components/authenticate-register/AuthenticateRegister"
    )
);
const StatisticsReports = lazy(
  () => import("../pages/statistics/reports/charts")
);
const StatisticsReportsDetail = lazy(
  () => import("../pages/statistics/reports/details")
);
const AccountRegister = lazy(
  () => import("../pages/support/detail/AccountRegister")
);
const ContactUs = lazy(() => import("../pages/support/contact-us/ContactUs"));
const Payment = lazy(() => import("../pages/payment"));
const Certificate = lazy(() => import("../pages/certificate"));

export const router = createBrowserRouter([
  {
    path: "/",
    element: <DashboardRootPage />,
    errorElement: <Page500 />,
    children: [
      {
        path: routesConfig.homePage,
        errorElement: <Page500 />,
        children: [{ index: true, element: <HomePage /> }],
      },
      {
        path: routesConfig.welcome,
        errorElement: <Page500 />,
        children: [{ index: true, element: <Welcome /> }],
      },
      {
        path: routesConfig.infoUser,
        errorElement: <Page500 />,
        children: [{ index: true, element: <InfoUser /> }],
      },
      {
        path: routesConfig.deletePolicy,
        errorElement: <Page500 />,
        children: [{ index: true, element: <PolicyInfo /> }],
      },
      {
        path: routesConfig.verification,
        errorElement: <Page500 />,
        children: [{ index: true, element: <VerificationPage /> }],
      },
      {
        path: routesConfig.resultDelete,
        errorElement: <Page500 />,
        children: [{ index: true, element: <ResultDelete /> }],
      },
      {
        path: routesConfig.courseInfo,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseInformation /> }],
      },
      {
        path: routesConfig.searchCourseInfo,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchCourseInfo /> }],
      },
      {
        path: routesConfig.curriculumPrograms,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchEducationalPrograms /> }],
      },
      {
        path: routesConfig.statisticsCourses,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsCourses /> }],
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityDetail}/:slug`,
        errorElement: <Page500 />,
        element: <CourseDetail />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="1" />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityCourseTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="2" />,
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityCourseProgramTemplateDetail}`,
        errorElement: <Page500 />,
        element: <GeneralCourseDetail tab="3" />,
      },
      {
        path: routesConfig.searchSchool,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchSchools /> }],
      },
      {
        path: routesConfig.searchByTopic,
        errorElement: <Page500 />,
        children: [{ index: true, element: <SearchByTopic /> }],
      },
      {
        path: `${routesConfig.courseInfo}/detail/:id`,
        children: [{ index: true, element: <CourseEvaluation /> }],
      },
      {
        path: routesConfig.courseTopics,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseTopics /> }],
      },
      {
        path: routesConfig.courseTopics,
        children: [{ index: true, element: <CourseTopics /> }],
      },
      {
        path: `${routesConfig.curriculumPrograms}/detail/:id`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CourseProgramDetailTemplate /> }],
      },
      {
        path: routesConfig.systemInfo,
        children: [{ index: true, element: <SystemInfo /> }],
      },
      {
        path: routesConfig.joinPlatform,
        children: [{ index: true, element: <JoinPlatform /> }],
      },
      {
        path: `${routesConfig.searchSchool}/${routesConfig.universityTemplateDetail}/${routesConfig.blog}`,
        children: [{ index: true, element: <BlogPage /> }],
      },
      {
        path: routesConfig.support,
        children: [
          { index: true, element: <SupportPage /> },
          {
            path: routesConfig.supportAccountRegister,
            element: <AccountRegister />,
          },
          {
            path: routesConfig.contactUs,
            element: <ContactUs />,
          },
        ],
      },

      {
        path: routesConfig.statisticsUniversities,
        children: [{ index: true, element: <StatisticsUniversities /> }],
      },
      {
        path: routesConfig.supportDetail,
        children: [{ index: true, element: <DetailSupport /> }],
      },
      {
        path: routesConfig.viewSystemSupportInformation,
        children: [{ index: true, element: <ViewSystemSupportInformation /> }],
      },
      {
        path: routesConfig.viewSystemSupportInformation,
        children: [{ index: true, element: <ViewSystemSupportInformation /> }],
      },
      {
        path: `${routesConfig.courseEnrollment}/:id`,
        children: [{ index: true, element: <CourseEnrollment /> }],
      },
      {
        path: `${routesConfig.savedCourses}`,
        children: [{ index: true, element: <SavedCourses /> }],
      },
      {
        path: `${routesConfig.studySchedule}`,
        children: [{ index: true, element: <StudySchedule /> }],
      },
      {
        path: `${routesConfig.accountDeletionPolicy}`,
        children: [{ index: true, element: <AccountDeletionPolicy /> }],
      },
      {
        path: routesConfig.deleteSuccess,
        element: <DeleteSuccess />,
      },
    ],
  },
  {
    path: routesConfig.notification,
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.notification,
        children: [{ index: true, element: <NotificationPage /> }],
      },
    ],
  },
  {
    path: `${routesConfig.learningCourseStructure}/:id`,
    element: <AuthPage />,
    children: [{ index: true, element: <LearningCourseStructure /> }],
  },
  {
    path: routesConfig.myCourses, // my-survey
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.myCourses,
        errorElement: <Page500 />,
        children: [{ index: true, element: <MyCourse /> }],
      },
    ],
  },
  {
    path: routesConfig.statisticsReport,
    element: <AuthPage />,
    children: [
      {
        path: routesConfig.statisticsReport,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsReports /> }],
      },
      {
        path: `${routesConfig.statisticsReport}/detail`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <StatisticsReportsDetail /> }],
      },
    ],
  },
  {
    path: routesConfig.mySurveys,
    element: <AuthPage />,
    children: [
      {
        errorElement: <Page500 />,
        children: [
          {
            index: true,
            element: <MySurvey />,
          },
          {
            path: `${routesConfig.surveyRespond}/:id/:type/:theme`, // survey-res
            element: <SurveyRespond />,
          },
        ],
      },
    ],
  },
  {
    path: routesConfig.blog,
    element: <AuthPage />,
    children: [
      {
        path: `/${routesConfig.blog}/${routesConfig.topic}`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <TopicComponent /> }],
      },
      {
        path: `/${routesConfig.blog}/${routesConfig.topic}/${routesConfig.comment}`,
        errorElement: <Page500 />,
        children: [{ index: true, element: <CommentComponent /> }],
      },
    ],
  },
  {
    errorElement: <Page500 />,
    path: routesConfig.identifyEkyc,
    element: <AuthPage />,
    children: [{ index: true, element: <IdentifyEkyc /> }],
  },
  {
    errorElement: <Page500 />,
    path: `${routesConfig.payment}/:id`,
    element: <AuthPage />,
    children: [{ index: true, element: <Payment /> }],
  },
  {
    errorElement: <Page500 />,
    path: `${routesConfig.certificate}/:id`,
    element: <AuthPage />,
    children: [{ index: true, element: <Certificate /> }],
  },

  {
    path: routesConfig.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: routesConfig.login,
    element: <Login />,
  },
  {
    path: `${routesConfig.scormVebView}/:id`,
    element: <ScormVebView />,
  },

  {
    path: routesConfig.register,
    element: <Register />,
  },
  {
    path: `${routesConfig.register}/${routesConfig.authenticateRegister}`,
    element: <AuthenticateRegister />,
  },
  {
    path: routesConfig.forgotPassword,
    element: <ForgotPassword />,
  },
]);
