export const routesConfig = {
  listUsers: "/list-account-users",
  login: "/login",
  register: "/register",
  authenticateRegister: "authenticate-register",
  forgotPassword: "/forgot-password",
  homePage: "/",
  infoUser: "/info-user",
  identifyEkyc: "/identify-ekyc",
  deletePolicy: "/delete-policy",
  verification: "/verification",
  resultDelete: "/result-delete",
  welcome: "/welcome",
  courseDetail: "/course-detail", // Chi tiết khoá học
  universityDetail: "university-detail",
  universityTemplateDetail: "university-template-detail", //giới thiệu chung về trường
  universityCourseTemplateDetail: "university-course-template-detail", //danh sách khóa học của trường
  universityCourseProgramTemplateDetail:
    "university-course-program-template-detail", //danh sách chương trình học của trường
  courseInfo: "/course-info", // Thông tin khoá học
  courseTopics: "/course-topics", // Các chủ đề
  searchCourseInfo: "/course-info/search", // Thông tin khoá học
  curriculumPrograms: "/curriculum-programs", //tim kiem chuong trinh hoc
  statisticsCourses: "/statistics/courses", // Thống kê khoá học
  searchSchool: "/school/search", // Tìm kiếm trường đại học của hệ thống
  searchByTopic: "search-by-topic", //tim kiem theo chủ đề
  systemInfo: "/system-info", // Xem thông tin chung của hệ thống
  support: "/support", // Xem thông tin hỗ trợ của hệ thống
  supportAccountRegister: "/support/account-register", // Hỗ trợ đăng ký tài khoản
  contactUs: "/support/contact-us", // liên hệ với húng tôi
  notification: "/notification", // Xem thông báo hệ thống
  // courseEvaluation: "course-evaluation", //danh gia chat luong khoa hoc
  courseProgramDetail: "view-course-program-detail", // xem chi tiet chương trình học
  statisticsUniversities: "/statistics/universities", // Thống kê khoá học,
  supportDetail: "/support/detail", // Xem chi tiết thông tin hỗ trợ
  viewSystemSupportInformation: "/support/view-system-support-information",
  courseEnrollment: "/course-enrollment",
  savedCourses: "/saved-courses", // khoa hoc da luu
  myCourses: "/my-courses", // khoá học của tôi
  coursePractice: "/practice", // Làm bài tập, kiểm tra, bài thi
  learningCourseStructure: "/learning-course-structure", //xem hoc lieu
  studySchedule: "/study-schedule", // lich hoc
  topic: "topic", //chủ đề
  comment: "comment", //bình luận
  blog: "blog", // lich hoc
  accountDeletionPolicy: "/account-deletion-policy", // Chính sách xoá tài khoản
  deleteSuccess: "/delete-success", // xoa tai khoan thanh cong
  mySurveys: "my-surveys", // Danh sách khảo sát cho sinh viên
  surveyRespond: "survey-respond", // Trả lời khảo sát cho sinh viên
  joinPlatform: "join-platform", // Trả lời khảo sát cho sinh viên
  statisticsReport: "/statistics-report", // Thống kê báo cáo
  statisticsReportDetail: "/statistics-report/detail", // Thống kê báo cáo
  payment: "/payment", // Thanh toán
  certificate: "/certificate",
  scormVebView: "/scorm-web-view",
};
